<template>
    <div class="news-template-container">
        <div class="news-template">
        </div>
    </div>
</template>

<script>
  export default {
  }
</script>

<style>
    .news-template-container{
        padding-top: 100px;
        min-height: 100%;
        box-sizing: border-box;
    }
    .news-template-container .news-template{
        overflow: hidden;
    }
    .news-template-container .study-case-content{
        background-color: #F7F8FA;
        overflow: hidden;
    }
    .news-template-container .study-case-classify{
        padding: 50px 0;
        box-sizing: border-box;
    }
    .news-template-container .study-case-classify:nth-child(even){
        background-color: #ffffff;
    }
    .news-template-container .study-case-classify h2{
        text-align: center;
        font-size: 30px;
        color: #2E353D;
        line-height: 40px;
    }
    .news-template-container .study-case-classify h2:after{
        display: block;
        content: '';
        overflow: hidden;
        width: 60px;
        height: 3px;
        background-color: #B50707;
        margin: 4px auto 30px;
    }
    .news-template-container .study-case-classify ul{
        width: 1125px;
        height: 218px;
        display: -webkit-flex; /* Safari */
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 auto;
        overflow: hidden;
        transition: height .5s;
    }
    .news-template-container .study-case-classify ul.toggle{
        height: auto;
    }
    .news-template-container .study-case-classify li{
        width: 362px;
        height: 203px;
        overflow: hidden;
        position: relative;
        margin: 0 6px 15px 6px;
    }
    .news-template-container .study-case-classify li a{
        display: inline-block;
    }
    .news-template-container .study-case-classify li a:after{
        content: '';
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        left: 0;
    }
    .news-template-container .study-case-classify li .pic-url{
        transition: all 0.7s ease;
    }
    .news-template-container .study-case-classify li .icon{
        position: absolute;
        bottom: 3px;
        left: 15px;
        z-index: 9;
    }
    .news-template-container .study-case-classify li .time{
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: #B8BABB;
        font-size: 14px;
        z-index: 9;
    }
    .news-template-container .study-case-classify li:hover .pic-url{
        transform: scale(1.2);
        transition: all 0.7s ease;
    }
    .news-template-container .study-case-classify .no-data{
        text-align: center;
        color: #848484;
    }
    .news-template-container .study-case-classify .more{
        text-align: center;
    }
    .news-template-container .study-case-classify .more span{
        cursor: pointer;
        display: inline-block;
        width: 148px;
        height: 38px;
        line-height: 38px;
        border: 1px solid #848484;
        border-radius:10px;
        color: #848484;
    }
</style>
